<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="650px">
				<span slot="title"
					  class="dialog-title">
					<span>出库信息查询</span>
					<img src="@/assets/images/close.png"
						 alt=""
						 @click="visible = false">
				</span>
		<div class="pb0">
			<el-form :model="dataForm"
					 :rules="dataRule"
					 label-position="top"
					 ref="dataForm">
				<div class="inline-form-item">
					<el-form-item label="序列号"
								  filterable
								  prop="serialNo">
						<el-input v-model="dataForm.serialNo"
								  placeholder="请输入"
								  clearable
								  maxlength="64"
								  @clear="handleClear"></el-input>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<div class="pt5 content" v-if="content">
			<p>机号：{{content.machineNo || ''}}</p>
			<p>PPS号：{{ content.ppsNo || '' }}</p>
			<p>商品编号：{{ content.productCode || ''}}</p>
			<p>机型编号：{{ content.modelCode || ''}}</p>
			<p>出库日期：{{ content.outDate || ''}}</p>
		</div>
		<span slot="footer"
			  class="dialog-footer pt0">
				<el-button :loading="submitLoading"
						   class="submit-btn"
						   @click="formSubmit"> 查 询 </el-button>
			</span>
	</el-dialog>
</template>
<script>
import {debounce} from '@/utils/debounce'

export default {
	data() {
		return {
			submitLoading: false,
			visible: false,
			dataForm: {
				serialNo: '',
			},
			dataRule: {
				serialNo: [
					{required: true, message: '序列号不能为空', trigger: "blur"}
				],
			},
			content: ''
		}
	},
	created() {
	},
	methods: {
		init(type, data) {
			this.visible = true;
			this.$nextTick(() => {
				this.content = ''
				this.$refs.dataForm.resetFields();
			})
		},
		formSubmit: debounce(function () {
			if (this.visible === false) {
				return
			}
			this.content = ''
			this.submitLoading = true;
			this.$refs['dataForm'].validate((valid) => {
				this.dataForm.serialNo = this.dataForm.serialNo.trim()
				if (valid) {
					let that = this
					that.$request.get(
						"claimAuthRepairOrder",
						false,
						that.dataForm,
						function (r) {
							if (r.code === "0") {
								that.content = r.data
							} else {
								that.$message.error(r.msg)
							}
							that.submitLoading = false
						}
					)
				} else {
					this.submitLoading = false
					return false
				}
			})
		}, 1000),
		// 清空
		handleClear() {
			this.content = ''
		},
	}
}
</script>
<style lang="scss"
	   scoped>
.dialog-title {
	display: block;
	text-align: center;

	span {
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}

	img {
		width: 28px;
		float: right;
		cursor: pointer;
	}
}

.el-form-item {
	margin-bottom: 10px;

	.el-select {
		width: 100%;
	}
}

.submit-btn {
	width: 100px;
	background-color: #117FFC;
	color: #fff;
}

.content {
	p {
		line-height: 26px;
	}
}

.role-dialog /deep/ .el-dialog {
	border-radius: 10px !important;
}

.role-dialog /deep/ .el-dialog__body {
	padding: 0 20px !important;
}

.role-dialog /deep/ .el-form-item__label {
	padding: 0 !important;
}

.role-dialog /deep/ .el-dialog__footer {
	padding: 20px 20px 30px !important;
}
</style>
