<template>
    <el-dialog
        :visible.sync="visible"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        class="role-dialog"
        width="350px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
        <el-form :model="dataForm" :rules="dataRule" label-position="top" ref="dataForm">
            <el-form-item label="机型名称" prop="deviceName">
                <el-input v-model="dataForm.deviceName" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
            </el-form-item>
            <el-form-item label="商品编号" prop="productNumber">
                <el-input v-model="dataForm.productNumber" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
            </el-form-item>
            <el-form-item label="机型分类" prop="deviceType">
                <el-select v-model="dataForm.deviceType" :disabled="readonly">
                    <el-option v-for="(item, i) in claimDeviceOptions" :key="i" :value="item.id" :label="item.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="课程编号" prop="courseNumber">
                <el-input v-model="dataForm.courseNumber" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
            </el-form-item>
            <el-form-item label="机型名称说明" prop="deviceRemark">
                <el-input v-model="dataForm.deviceRemark" placeholder="请输入" maxlength="128" :disabled="readonly"></el-input>
            </el-form-item>
            <el-form-item label="保修期内服务商" prop="warrantyService">
                <el-select v-model="dataForm.warrantyService" :disabled="readonly" clearable>
                    <el-option v-for="(item, i) in warrantyServiceOptions" :key="i" :value="item.id" :label="item.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="特约维修服务费" prop="specialServiceFee">
                <el-input v-model.number="dataForm.specialServiceFee" type="number" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
            </el-form-item>
            <el-form-item label="委托维修服务费" prop="entrustServiceFee">
                <el-input v-model.number="dataForm.entrustServiceFee" type="number" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
            </el-form-item>
            <el-form-item label="保修时长" prop="warrantyDuration">
                <el-input v-model.number="dataForm.warrantyDuration" type="number" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
			<el-button v-if="!readonly" class="submit-btn" @click="submit">确定</el-button>
		</span>
    </el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
import { warrantyServiceOptions } from "@/constant/options"

export default {
    data() {
        return {
            title: '新增机型',
            visible: false,
            readonly: false,
            warrantyServiceOptions,
            claimDeviceOptions: [],
            dataForm: {
                deviceName: '',             // 机型名称
                productNumber: '',          // 商品编号
				deviceType: '',             // 机型分类
                courseNumber: '',           // 课程编号
                deviceRemark: '',           // 机型名称说明(来自技术中心)
                warrantyService: '',        // 保修期内服务商 1、特约维修店   2、销售服务商
                specialServiceFee: '',      // 特约维修服务费
                entrustServiceFee: '',      // 委托维修服务费
                warrantyDuration: '',       // 保修时长
            },
            submitUrl: 'repairModelAdd',
            dataRule: {
                deviceName: [
                    { required: true, message: '机型名称不能为空', trigger: "blur" }
                ],
                productNumber: [
                    { required: true, message: '商品编号不能为空', trigger: "blur" }
                ],
                deviceType: [
                    { required: true, message: '机型分类不能为空', trigger: "change" }
                ],
            },
        }
    },
    created() {
        this.getClaimDeviceList()
    },
    methods: {
        init(type, data) {
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                if (type == 'add') {
                    this.title = '新增机型';
                    this.submitUrl = 'repairModelAdd';
                    this.readonly = false;
                    delete this.dataForm.id;
                } else if (type == 'edit') {
                    this.title = '修改机型';

                    this.dataForm.deviceName = data.deviceName;
                    this.dataForm.productNumber = data.productNumber;
                    this.dataForm.deviceType = data.deviceType;
                    this.dataForm.courseNumber = data.courseNumber;
                    this.dataForm.deviceRemark = data.deviceRemark;
                    this.dataForm.warrantyService = data.warrantyService;
                    this.dataForm.specialServiceFee = data.specialServiceFee;
                    this.dataForm.entrustServiceFee = data.entrustServiceFee;
                    this.dataForm.warrantyDuration = data.warrantyDuration;
                    this.dataForm.id = data.id;
                    this.submitUrl = 'repairModelUpdate';
                    this.readonly = false;
                } else {
                    this.title = '查看机型';
                    this.dataForm.deviceName = data.deviceName;
                    this.dataForm.productNumber = data.productNumber;
                    this.dataForm.deviceType = data.deviceType;
                    this.dataForm.courseNumber = data.courseNumber;
                    this.dataForm.deviceRemark = data.deviceRemark;
                    this.dataForm.warrantyService = data.warrantyService;
                    this.dataForm.specialServiceFee = data.specialServiceFee;
                    this.dataForm.entrustServiceFee = data.entrustServiceFee;
                    this.dataForm.warrantyDuration = data.warrantyDuration;
                    this.submitUrl = 'repairModelUpdate';
                    this.readonly = true;
                }
            })
        },
        submit: debounce(function () {
            if (this.visible == false) {
                return
            }
            this.$refs['dataForm'].validate(valid => {
                if (valid) {
                    let that = this;
                    that.$request.post(
                        that.submitUrl,
                        true,
                        that.dataForm,
                        function (r) {
                            if (r.code == "0") {
                                that.visible = false;
                                that.$emit('refresh');
                                that.$message.success('保存成功');
                            } else {
                                that.$message.error(r.msg);
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        }, 1000),
        // 获取机型分类数据
        getClaimDeviceList() {
            let that = this;
            that.$request.post(
                "sysDataDetailPageList",
                false,
                {
                    pageNo: 1,
                    pageSize: 999,
                    code: 'claimDeviceType'
                },
                function (r) {
                    if (r.code == "0") {
                        that.claimDeviceOptions = r.data.list;
                    } else {
                        that.$message.error(r.msg);
                    }
                }
            );
        },
    }
}
</script>
<style lang="scss" scoped>
.dialog-title {
    display: block;
    text-align: center;

    span {
        color: #222;
        font-weight: 600;
        font-size: 20px;
    }

    img {
        width: 28px;
        float: right;
        cursor: pointer;
    }
}

.el-form-item {
    margin-bottom: 10px;

    .el-select {
        width: 100%;
    }
}

.submit-btn {
    width: 100%;
    background-color: #117FFC;
    color: #fff;
}

.role-dialog /deep/ .el-dialog {
    border-radius: 10px !important;
}

.role-dialog /deep/ .el-dialog__body {
    padding: 0 20px !important;
}

.role-dialog /deep/ .el-form-item__label {
    padding: 0 !important;
}

.role-dialog /deep/ .el-dialog__footer {
    padding: 20px 20px 40px !important;
}
</style>
