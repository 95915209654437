<template>
	<div class="data-content">
		<div class="searchbar">
			<div class="pr10" style="display: flex;">
				<button v-if="perms.includes('home:repair:model:add')"
						class="add-btn"
						@click="handleAddEdit('add')">新增
				</button>
				<button v-if="perms.includes('home:repair:model:check')"
						class="out-btn"
						@click="handleInfoQuery()">出库信息查询
				</button>
			</div>
			<el-form
				ref="form"
				:inline="true"
				label-width="86px"
				size="small"
				:show-message="false"
				class="search-form"
			>
				<el-form-item label="关键字">
					<el-input v-model="searchKey"  clearable placeholder="请输入关键字"></el-input>
				</el-form-item>
				<el-form-item label="机型分类">
					<el-select v-model="deviceType" placeholder="全部">
						<el-option
							v-for="option in claimDeviceOptions"
							:key="option.id"
							:label="option.name"
							:value="option.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-button class="search-btn" @click="getDataList"></el-button>
				<el-button icon="el-icon-refresh-right"
						   class="refresh-btn"
						   @click="handleReset"></el-button>
			</el-form>
		</div>
		<div class="data-list-box">
			<el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				class="scroll-table"
				style="width: 100%;">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{ scope.$index + 1 }}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="deviceName"
					label="机型"
				></el-table-column>
				<el-table-column
					prop="productNumber"
					label="商品编号"
					width="120"
				></el-table-column>
				<el-table-column
					prop="courseNumber"
					label="课程编号"
					width="180"
				></el-table-column>
				<el-table-column
					prop="warrantyService"
					label="保修期内服务商"
					width="180"
				>
					<template slot-scope="scope">
						<el-tag v-if="getWarrantyServiceName(scope.row)"> {{
								getWarrantyServiceName(scope.row)
							}}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					prop="deviceType"
					label="机型分类"
					width="180"
				>
					<template slot-scope="scope">{{ getClaimDeviceName(scope.row) }}</template>
				</el-table-column>
				<el-table-column
					prop="specialServiceFee"
					label="特约维修服务费"
					width="120"
				>
					<template slot-scope="scope"> {{ formatPriceString(scope.row.specialServiceFee) }}</template>
				</el-table-column>
				<el-table-column
					prop="entrustServiceFee"
					label="委托维修服务费"
					width="120"
				>
					<template slot-scope="scope"> {{ formatPriceString(scope.row.entrustServiceFee) }}</template>
				</el-table-column>
				<el-table-column
					prop="warrantyDuration"
					label="保修时长"
					width="100"
				></el-table-column>

				<el-table-column
					fixed="right"
					label="操作"
					width="130">
					<template slot-scope="scope">
						<button class="btn-blue"
								@click="handleAddEdit('check', scope.row)">查看
						</button>
						<button v-if="perms.includes('home:repair:model:update')"
								class="btn-blue"
								@click="handleAddEdit('edit', scope.row)">编辑
						</button>
						<button v-if="perms.includes('home:repair:model:delete')"
								class="btn-red"
								@click="handleDelete(scope.row)">删除
						</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-model ref="editModel"
					@refresh="getDataList"></edit-model>
		<outboundInfoQuery ref="outboundInfoQuery"></outboundInfoQuery>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import EditModel from './components/edit-model'
import {warrantyServiceOptions} from "@/constant/options"
import outboundInfoQuery from "./components/outboundInfoQuery.vue";

export default {
	components: {
		Pagination,
		EditModel,
		outboundInfoQuery
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			dataList: [],
			searchKey: '',
			pageNo: 1,
			pageSize: 10,
			total: 0,
			deviceType:'',
			visible: false,
			errorList: [],
			claimDeviceOptions: [], //机型分类
			warrantyServiceOptions,
		}
	},
	created() {
		this.getClaimDeviceList()
		this.getDataList()
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row, rowIndex}) {
			if (rowIndex % 2 == 1) {
				return 'blue'
			} else {
				return 'yellow'
			}
		},
		handleSizeChange() {
			this.getDataList();
		},
		handleCurrentChange() {
			this.getDataList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getDataList();
		},
		// 重置查询条件
		handleReset() {
			this.searchKey = '';
			this.pageNo = 1;
			this.deviceType = '';
			this.getDataList();
		},
		getWarrantyServiceName(row) {
			for (const option of this.warrantyServiceOptions) {
				if (option.id === row.warrantyService) {
					return option.name;
				}
			}
			return ""
		},
		formatPriceString(price) {
			if (price === null || price === undefined) return ""
			if (typeof price === "string" && price.trim() === "") return ""
			try {
				const p = parseInt(price)
				if (isNaN(p)) return price
				return p.toLocaleString("zh-CN", {style: 'currency', currency: 'CNY'})
			} catch (e) {
				return price
			}
		},
		getDataList() {
			let that = this;
			that.$request.post(
				"repairModelPageList",
				true,
				{
					searchKey: that.searchKey,
					// type: that.roleType,
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					deviceType: that.deviceType
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleAddEdit(type, data) {
			this.$refs.editModel.init(type, data);
		},
		handleDelete(data) {
			this.$confirm('是否删除当前机型?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'repairModelDelete',
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.getDataList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 获取机型分类数据
		getClaimDeviceList() {
			let that = this;
			that.$request.post(
				"sysDataDetailPageList",
				false,
				{
					pageNo: 1,
					pageSize: 999,
					code: 'claimDeviceType'
				},
				function (r) {
					if (r.code == "0") {
						that.claimDeviceOptions = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取对应机型名称
		getClaimDeviceName(row) {
			for (const option of this.claimDeviceOptions) {
				if (option.id === row.deviceType) {
					return option.name;
				}
			}
			return ""
		},
		// 查询信息
		handleInfoQuery() {
			this.$refs.outboundInfoQuery.init('infoQuery', '');
		}
	}
}
</script>

<style lang="scss"
	   scoped>
.block {
	display: block;
}

.data-content {
	.top-box {
		// height: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.add-btn {
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}

	.out-btn {
		display: inline-block;
		width: 120px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}

	.import-btn {
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}

	.template-btn {
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}

	.btn-blue {
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}

	.btn-red {
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}

	.searchbar {
		.el-date-editor--daterange.el-input,
		.el-date-editor--daterange.el-input__inner {
			width: 228px;
		}

		.table-toolbar-btn {
			margin-left: 0;
			margin-right: 10px;
			margin-bottom: 10px;
		}
	}

	.search-btn {
		float: unset !important;
		border: none;
		padding: 0 !important;
		margin: 0 !important;
		margin-left: 30px !important;
		vertical-align: top;

	}

	.refresh-btn {
		width: 32px;
		height: 32px;
		border: none;
		color: #117FFC;
		padding: 0;
		background-color: rgba(17, 127, 252, 0.2);
		font-weight: bold;
		font-size: 22px;
		vertical-align: 8px;
		margin-left: 5px;

		&:hover {
			background-color: rgba(17, 127, 252, 0.3);
		}
	}
}

.dialog-title {
	display: block;
	text-align: center;

	span {
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}

	img {
		width: 28px;
		float: right;
		cursor: pointer;
	}
}

.error-list {
	height: 50vh;
	box-sizing: border-box;
	padding: 20px;
	overflow: auto;

	li {
		list-style: none;
	}
}

@media screen and (max-width: 1998px) {
	.search-form {
		max-width: 980px;
	}
}

@media screen and (max-width: 1113px) {
	.search-form {
		max-width: 444px;
	}
}

@media screen and (max-width: 723px) {
	.search-form {
		max-width: 312px;
	}
}

::v-deep .searchbar .search-form .el-select .el-input {
	min-width: 160px !important;
}

</style>

<style>
.el-table__fixed-right::before {
	background-color: transparent !important;
}

.scroll-table {
	border: 1px solid #dcdfe6;
}

.el-message .user-message {
	margin: 15px;
}
</style>
